<template>
<div>
  <v-dialog
      v-model="dialog"
  width="800px"
  height="300px">
  <v-card>
    <v-card-title>
      Freie Bahn wählen
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text
        style="height:80px">
      <v-row
      class="mt-5">
            <v-btn
                v-for="n in comp_Freeplaces"
                :key="n.id"
                class="mx-1 my-1"
                @click="save_place(n.place)"
                          >
              {{ n.place }}
            </v-btn>
      </v-row>
    </v-card-text>

  </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  props:{
    dialog: {
      type: Boolean,
    },
  },
  name: "freeplaces",
  data: () => ({

  }),
  computed:{
    comp_Freeplaces() {
      return this.$store.state.UserApp.ShootingRangeFreeplaces;
    },
  },
  mounted() {
  },
  methods: {
    save_place(place) {
      this.$emit('close-dialog', place);
    },

  },
}
</script>

<style scoped>

</style>