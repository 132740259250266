<template>
  <div class="mt-4  px-5">
    <v-alert
        v-show="!comp_OpenShootingBookData_CountOwnWeapons"
        color="red"
        type="error">
      Du hast noch keine Waffe hinterlegt, bitte gehe auf <a href="/user/weapon">Meine Waffen</a>
      und füge zuerst deine Waffen hinzu!
    </v-alert>
    <v-alert
        v-show="comp_OpenShootingBookData_Club_DefaultClub"
        color="red"
        type="error">
      Du hast keinen Standardverein festgelegt, bitte stelle auf deinem <a href="/user/profil">Profil</a>
      zuerst deinen Verein ein, bzw. lasse ihn vom Verein bestätigen!
    </v-alert>

    <v-card
        class="mt-4 mb-4"
        v-if="this.comp_OpenShootingBookData_CountOwnWeapons > 0"
    >
      <v-card-title>Neuer Eintrag im schuetzenbuch</v-card-title>
      <v-divider></v-divider>
      <v-tabs
          v-model="tab"
          background-color="success accent-4"
          center-active
          dark
      >
        <v-tab href="#internal">schuetzenbuch Eintrag</v-tab>
        <v-tab href="#external">Externer Eintrag</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item value="internal"
                      v-if="!comp_OpenShootingBookData_Club_DefaultClub & this.comp_OpenShootingBookData_CountOwnWeapons > 0">
            <v-card-text>
              <v-container>
                <freeplaces
                    v-show="freeplaces"
                    :dialog="freeplaces"
                    :retain-focus="false"
                    @close-dialog="closeDialog"></freeplaces>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Verein"
                        v-model="comp_OpenShootingBookData_Club"
                        outlined
                        readonly
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.shootingAction"
                        :items="comp_Shootingbook_Action"
                        item-text="Funktion"
                        item-value="value"
                        label="Funktion"
                        value=""
                        @change="set_Options"
                        return-object
                        outlined
                        class="mx-1"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.function }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.function }}
                      </template>

                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.aktivitaet"
                        :items="comp_OpenShootingBookData_Trainingart"
                        item-text="Aktivität"
                        item-value="id"
                        label="Aktivität"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :disabled="disabledSelects"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.aktivitaet }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.aktivitaet }}
                      </template>

                    </v-select>
                  </v-col>
                  <v-col>

                    <v-select
                        v-model="comp_OpenShootingBookData_defaultClubRanges"
                        :items="comp_OpenShootingBookData_ClubRanges"
                        item-text="Stand"
                        item-value="id"
                        label="Stand"
                        @change="get_Freeplaces"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :disabled="disabledSelectsSL"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.LangBez }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.LangBez }}
                      </template>

                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="newShootingBook.Place"
                        label="Bahn"
                        @click="freeplaces = true"
                        outlined
                        :disabled="disabledSelects"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.OwnWeapon"
                        :items="comp_OpenShootingBookData_OwnWeapons"
                        item-text="Waffe"
                        item-value="id"
                        label="Waffe"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :disabled="disabledSelects"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.KurzT }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.KurzT }}
                      </template>

                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.Disziplin"
                        :items="comp_OpenShootingBookData_DisziWeapon"
                        item-text="Disziplin"
                        item-value="id"
                        label="Disziplin"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :retain-focus="false"
                        :disabled="disabledSelects"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.Nummer }} {{ data.item.LangBez }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.Nummer }} {{ data.item.LangBez }}
                      </template>

                    </v-select>
                  </v-col>
                  <v-col>

                    <v-datetime-picker
                        label="Begin"
                        date-format="dd.MM.yyyy"
                        time-format="HH:mm"
                        v-model="newShootingBook.Begin"
                        :text-field-props="textFieldProps"
                        :time-picker-props="timePickerProps"
                        :date-picker-props="datePickerProps"
                        :retain-focus="false"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>

                  </v-col>
                </v-row>
              </v-container>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="success justify-right" text @click="save()">
                <v-icon class="mr-2">
                  far fa-save
                </v-icon>
                sichern
              </v-btn>

            </v-card-actions>
          </v-tab-item>

          <v-tab-item value="external">
            <v-card-text>
              <v-container>

                <v-row>
                  <v-col>
                    <v-text-field
                        label="Verein"
                        v-model="external_club_name"
                        outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.aktivitaet"
                        :items="comp_OpenShootingBookData_Trainingart"
                        item-text="Aktivität"
                        item-value="id"
                        label="Aktivität"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :disabled="disabledSelects"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.aktivitaet }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.aktivitaet }}
                      </template>

                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="newShootingBook.Place"
                        label="Bahn"
                        outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.OwnWeapon"
                        :items="comp_OpenShootingBookData_OwnWeapons"
                        item-text="Waffe"
                        item-value="id"
                        label="Waffe"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :disabled="disabledSelects"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.KurzT }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.KurzT }}
                      </template>

                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                        v-model="newShootingBook.Disziplin"
                        :items="comp_OpenShootingBookData_DisziWeapon"
                        item-text="Disziplin"
                        item-value="id"
                        label="Disziplin"
                        value=""
                        return-object
                        outlined
                        class="mx-1"
                        :retain-focus="false"
                        :disabled="disabledSelects"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.Nummer }} {{ data.item.LangBez }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.Nummer }} {{ data.item.LangBez }}
                      </template>

                    </v-select>
                  </v-col>
                  <v-col>
                    <v-datetime-picker
                        label="Datum"
                        date-format="dd.MM.yyyy"
                        time-format="HH:mm"
                        v-model="newShootingBook.Begin"
                        :text-field-props="textFieldProps"
                        :time-picker-props="timePickerProps"
                        :date-picker-props="datePickerProps"
                        :retain-focus="false"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Ergebniss"
                        v-model="external_TrGes"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col></v-col>

                </v-row>
              </v-container>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="success justify-right" text @click="save_externaL_shootingbook()">
                <v-icon class="mr-2">
                  far fa-save
                </v-icon>
                sichern
              </v-btn>

            </v-card-actions>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>


    </v-card>

    <OpenShootingBookEntry></OpenShootingBookEntry>
  </div>
</template>

<script>
import OpenShootingBookEntry from '../component/OpenShootingBookEntry';
import freeplaces from '../component/freeplaces.vue';

export default {
  name: "recordtime",
  components: {OpenShootingBookEntry, freeplaces},
  data: () => ({
    menu: false,
    menu2: false,
    tab: null,
    external_club_name: '',
    external_TrGes: '',
    disabledSelects: false,
    disabledSelectsSL: false,
    freeplaces: false,
    newShootingBook: {
      shootingAction: {function: 'Schütze', value: 1},
      aktivitaet: {id: 1},
      ClubRange: {},
      OwnWeapon: {},
      Disziplin: {},
      Begin: new Date(),
      Place: null,
    },
    WeaponDiszi: [],
    textFieldProps: {
      outlined: true,
    },
    datePickerProps: {},
    timePickerProps: {
      format: "24hr"
    },
    signature_style: {border: 'black 3px solid'},
  }),

  computed: {
    comp_OpenShootingBookData() {
      return this.$store.state.UserApp.OpenShootingBookData;
    },
    comp_OpenShootingBookData_Club() {
      return this.$store.state.UserApp.OpenShootingBookData.Club.name;
    },
    comp_OpenShootingBookData_Club_DefaultClub() {
      const state = this.$store.state.UserApp.OpenShootingBookData;
      var data = false;
      if (state != null && Object.keys(state).length > 0) {
        data = state.Club.Vereinsnummer === '99999';
      }
      return data;
    },
    comp_OpenShootingBookData_ClubRanges() {
      return this.$store.state.UserApp.OpenShootingBookData.ClubRanges;
    },
    comp_OpenShootingBookData_defaultClubRanges: {
      get() {
        return this.$store.state.UserApp.OpenShootingBookData.ClubRanges[0];
      },
      set(val) {
        this.newShootingBook.ClubRange = val;
      },
    },
    comp_OpenShootingBookData_OwnWeapons() {
      return this.$store.state.UserApp.Weapons;
    },
    comp_OpenShootingBookData_CountOwnWeapons() {
      return this.$store.state.UserApp.Weapons.length;
    },
    comp_OpenShootingBookData_Trainingart() {
      return this.$store.state.UserApp.OpenShootingBookData.trainingart;
    },
    comp_OpenShootingBookData_DisziWeapon() {
      let Data = this.$store.getters['UserApp/weapon_Disciplines'](this.newShootingBook.OwnWeapon.id)
      if (typeof Data === 'undefined' || Data.length === 0) {
        Data = this.$store.state.UserApp.Discipline;
      }
      return Data
    },
    comp_Shootingbook_Action() {
      let Data = [
        {function: 'Schütze', value: 1},
      ]
      if (this.$store.getters['UserApp/has_Education_Supervision']) {
        Data.push(
            {function: 'Standaufsicht', value: 2},
            {function: 'Schießsportleiter', value: 3}
        )
      }
      return Data
    },


  },
  beforeMount() {
    this.initData();
  },
  methods: {

    initData() {
      if (!this.comp_OpenShootingBookData_Club_DefaultClub && this.comp_OpenShootingBookData_CountOwnWeapons > 0) {
        this.newShootingBook.OwnWeapon = this.$store.state.UserApp.Weapons[0];
        this.newShootingBook.ClubRange = this.comp_OpenShootingBookData_defaultClubRanges;
        this.$store.dispatch('UserApp/getShootingRangeFreeplaces', this.newShootingBook.ClubRange.id)
      }
    },

    save() {
      var Data;
      switch (this.newShootingBook.shootingAction.value) {
        case 1:
          Data = {
            inputVereinID: this.newShootingBook.ClubRange.club_id,
            inputStand: this.newShootingBook.ClubRange.id,
            inputTrainingArt: this.newShootingBook.aktivitaet.id,
            inputDisz: this.newShootingBook.Disziplin.id,
            inputWaffe: this.newShootingBook.OwnWeapon.id,
            inputPlatz: this.newShootingBook.Place,
            inputSA: this.newShootingBook.shootingAction.value,
            inputBegin: this.newShootingBook.Begin,
          };
          break;
        case 2:
          Data = {
            inputVereinID: this.newShootingBook.ClubRange.club_id,
            inputStand: this.newShootingBook.ClubRange.id,
            inputSA: this.newShootingBook.shootingAction.value,
            inputBegin: this.newShootingBook.Begin,
          };
          break;
        case 3:
          Data = {
            inputVereinID: this.newShootingBook.ClubRange.club_id,
            inputSA: this.newShootingBook.shootingAction.value,
            inputBegin: this.newShootingBook.Begin,
          };
          break;
      }

      this.$store.dispatch('UserApp/setNewShootingBookRecord', Data)
          .then(this.set_defaultInputs())


    },

    save_externaL_shootingbook() {
      let Data = {
        ext_clubname: this.external_club_name,
        inputTrainingArt: this.newShootingBook.aktivitaet.id,
        inputDisz: this.newShootingBook.Disziplin.id,
        inputWaffe: this.newShootingBook.OwnWeapon.id,
        inputPlatz: this.newShootingBook.Place,
        datum: this.newShootingBook.Begin,
        trergges: this.external_TrGes
      };
      this.$store.dispatch('UserApp/setNewExternalShootingBookRecord', Data)
          .then(this.set_defaultInputs())

    },

    get_Freeplaces() {
      if (!this.disabledSelects) {
        this.$store.dispatch('UserApp/getShootingRangeFreeplaces', this.newShootingBook.ClubRange.id)
        this.freeplaces = true;
      }
    },
    set_Options() {
      switch (this.newShootingBook.shootingAction.value) {
        case 1:
          this.disabledSelects = false
          this.disabledSelectsSL = false
          break;
        case 2:
          this.disabledSelects = true
          break;
        case 3:
          this.disabledSelects = true
          this.disabledSelectsSL = true
          break;
      }
    },
    set_defaultInputs() {
      this.newShootingBook = {
        shootingAction: {function: 'Schütze', value: 1},
        aktivitaet: {id: 1},
        ClubRange: this.comp_OpenShootingBookData_defaultClubRanges,
        OwnWeapon: {},
        Disziplin: {},
        Begin: new Date(),
        Place: null,
      }
    },

    closeDialog(place) {
      this.freeplaces = false;
      this.newShootingBook.Place = place;
    },

  },
}
</script>

<style scoped>

</style>
